import {
  BudgetAmounts,
  BudgetComposanteSoutirage,
  OveruseBudget,
} from '@Collectif-Energie/budgetcomputer';
import {
  ConsumptionProfile,
  EndpointCategory,
  EndpointCommunicationCapability,
  EndpointConnection,
  NumberByTemporalClass,
  PowerConsumptionProfile,
  PowerEndpointCategory,
  TransportCode,
} from '@Collectif-Energie/collective-lib';
import {
  TransportRequestParameters,
  TransportRequestPerimeter,
  TransportRequestProperties,
} from '../../server/modules/business-data/models/business-data.interface';
import { CodeAndLibelle } from '../../server/modules/commons/types';
import { MeasurementByTemporalClass } from '../../server/modules/measurements/models/measurement.type';
import transportOrder from '../constants/transportOrder.constants';
import {
  EndpointServiceCostBudget,
  FetchTransportPerimeter,
  PowerTransport,
  PowerTransportPropertyType,
  TransportPerimeter,
} from './business-data/dto/power-transport.interface';
import { SalesforceProfilEnergetique } from '../../server/modules/salesforce/models/salesforce.interface';

export enum eJobStatus {
  Waiting = 'WAITING',
  Active = 'ACTIVE',
  Progress = 'PROGRESS',
  Completed = 'COMPLETED',
  Unavailable = 'UNAVAILABLE',
  Failed = 'FAILED',
}

export enum eAcheminementType {
  eCurrentAcheminement = 'Actuel',
  eOptimizedAcheminement = 'Optimisé',
  eAjustedAcheminement = 'Modulé',
}

export interface TransportProfile {
  prm: string;
  startDate: Date;
  endDate: Date;
  status: string;
  pointLivraison: DeliveryPoint;
  technicalData: TechnicalData;
  transports: Transport[];
}

export interface DeliveryPoint {
  prm: string;
  address: Address;
  operator: string;
  etatContractuel: string;
}

export interface Address {
  numeroEtNomVoie: string;
  codePostal: string;
  commune: CodeAndLibelle;
}

export interface TechnicalData {
  fta: TransportCode;
  programmationPosteHoraire: string;
  puissanceRaccordement: number;
  puissances: NumberByTemporalClass;
  typeCompteur: EndpointCategory;
  typeRaccordement: EndpointConnection;
  versionUtilisation: ConsumptionProfile;
  endpointCommunicationCapability: EndpointCommunicationCapability;
}

export interface Transport {
  acheminementType: string;
  cdpAnalyse: {
    analysedPeriodStartDate: Date;
    analysedPeriodEndDate: Date;
    puissanceMaxParClasse: MeasurementByTemporalClass;
    puissanceDepassementByTemporalClass: NumberByTemporalClass;
    nbHoursDepassementByTemporalClass: NumberByTemporalClass;
    consumptionByTemporalClass: NumberByTemporalClass;
    consommationTotale: number;
  };
  properties: TechnicalData;
  ajustmentMargin: number | null;
  budget: {
    endpointServiceCostBudget: EndpointServiceCostBudget;
    composanteComptage: BudgetAmounts;
    composanteDepassement: OveruseBudget;
    composanteGestion: BudgetAmounts;
    composanteSoutirage: BudgetComposanteSoutirage;
    montantHt: number;
    montantTtc: number;
    montantTva: number;
    taxes: {
      contributionTarifaireAcheminement: {
        montantHt: number;
        montantTtc: number;
        montantTva: number;
        tauxTva: number;
      };
    };
  };
}

export class TransportBudgetMapper {
  public buildTransportProfiles = (
    perimeter: FetchTransportPerimeter,
    powerTransports: PowerTransport[],
    profiles: SalesforceProfilEnergetique[]
  ): TransportProfile[] => {
    return profiles.reduce(
      (
        transportProfiles: TransportProfile[],
        profile: SalesforceProfilEnergetique
      ) => {
        const transports = powerTransports.filter(
          (transport) => transport.properties.prm === profile.prm
        );
        if (!transports.length) return transportProfiles;

        const transportProfile = this.buildTransportProfile(
          { prm: profile.prm, ...perimeter },
          profile
        );

        transportProfile.transports = this.sortTransports(
          transports.map((t) => this.buildTransport(t))
        );

        return transportProfiles.concat(transportProfile);
      },
      []
    );
  };

  public buildTransportProfile = (
    perimeter: TransportPerimeter,
    profile: SalesforceProfilEnergetique
  ): TransportProfile => {
    if (!profile.transportCode)
      throw new Error("La FTA renseignée dans Salesforce n'est pas reconnue");

    return {
      prm: profile.prm,
      startDate: perimeter.startDate,
      endDate: perimeter.endDate,
      status: 'PROGRESS',
      pointLivraison: {
        prm: profile.prm,
        address: {
          numeroEtNomVoie: profile.address.street,
          codePostal: profile.address.postalCode,
          commune: {
            code: profile.address.city,
            libelle: profile.address.city,
          },
        },
        etatContractuel: profile.contractualState,
        operator: profile.operator,
      },
      technicalData: {
        fta: profile.transportCode as TransportCode,
        programmationPosteHoraire: profile.endpointTimeProgramming,
        puissanceRaccordement: profile.connectionMaxPower,
        puissances: profile.powers,
        typeCompteur: profile.endpointCategory as EndpointCategory,
        typeRaccordement: profile.endpointConnection,
        versionUtilisation: profile.consumptionProfile,
        endpointCommunicationCapability:
          profile.endpointCommunicationCapability,
      },
      transports: [],
    };
  };

  public buildTransport = (powerTransport: PowerTransport): Transport => {
    const { properties, budget, analysis } = powerTransport;

    return {
      acheminementType: this.transformTransportType(properties.type),
      cdpAnalyse: {
        analysedPeriodStartDate: analysis.startDate,
        analysedPeriodEndDate: analysis.endDate,
        puissanceMaxParClasse: analysis.highestMeasurements,
        puissanceDepassementByTemporalClass: analysis.overusePowers,
        nbHoursDepassementByTemporalClass: analysis.overuseHours,
        consumptionByTemporalClass: analysis.consumptions,
        consommationTotale: analysis.totalConsumption,
      },
      properties: {
        fta: properties.transportCode,
        programmationPosteHoraire: properties.endpointTimeProgramming,
        puissanceRaccordement: properties.connectionMaxPower,
        puissances: properties.subscribedPowers,
        typeCompteur: properties.endpointCategory,
        typeRaccordement: properties.endpointConnection,
        versionUtilisation: properties.consumptionProfile,
        endpointCommunicationCapability:
          properties.endpointCommunicationCapability,
      },
      ajustmentMargin: properties.marginCoefficient
        ? Math.round((properties.marginCoefficient - 1) * 100)
        : null,
      budget: {
        endpointServiceCostBudget: budget.endpointServiceCostBudget,
        composanteComptage: budget.transportBudget.composanteComptage,
        composanteDepassement: budget.transportBudget.overuseBudget,
        composanteGestion: budget.transportBudget.composanteGestion,
        composanteSoutirage: budget.transportBudget.composanteSoutirage,
        montantHt: budget.amountExcludingVat,
        montantTtc: budget.amountIncludingVat,
        montantTva: budget.vatAmount,
        taxes: {
          contributionTarifaireAcheminement: {
            montantHt: budget.ctaBudget.amountExcludingVat,
            montantTtc: budget.ctaBudget.amountIncludingVat,
            montantTva: budget.ctaBudget.vatAmount,
            tauxTva: budget.ctaBudget.vatRate,
          },
        },
      },
    };
  };

  private transformTransportType = (
    type: PowerTransportPropertyType
  ): string => {
    switch (type) {
      case PowerTransportPropertyType.Current:
        return eAcheminementType.eCurrentAcheminement;
      case PowerTransportPropertyType.Optimize:
        return eAcheminementType.eOptimizedAcheminement;
      case PowerTransportPropertyType.Custom:
        return eAcheminementType.eAjustedAcheminement;
      default:
        throw new Error("Aucun type d'acheminement n'a été reconnu");
    }
  };

  public buildTransportRequestParameters(
    profile: SalesforceProfilEnergetique,
    perimeter: TransportPerimeter
  ): TransportRequestParameters {
    const transportPerimeter: TransportRequestPerimeter =
      this.buildTransportRequestPerimeter(profile, perimeter);
    const simulationTransportProperties: TransportRequestProperties =
      this.buildTransportRequestProperties(profile);

    return {
      transportPerimeter,
      simulationTransportProperties,
    };
  }

  public buildTransportRequestPerimeter = (
    profile: SalesforceProfilEnergetique,
    perimeter: TransportPerimeter
  ): TransportRequestPerimeter => {
    return {
      prm: profile.prm,
      startDate: perimeter.startDate,
      endDate: perimeter.endDate,
    };
  };

  public buildTransportRequestProperties = (
    profile: SalesforceProfilEnergetique
  ): TransportRequestProperties => {
    return {
      transportCode: profile.transportCode as TransportCode,
      endpointCategory: profile.endpointCategory as PowerEndpointCategory,
      endpointConnection: profile.endpointConnection,
      consumptionProfile: profile.consumptionProfile as PowerConsumptionProfile,
      subscribedPowers: profile.powers,
      endpointCommunicationCapability:
        profile.endpointCommunicationCapability ??
        EndpointCommunicationCapability.Local,
    };
  };

  public sortTransports(transports: Transport[]): Transport[] {
    return transports.sort(function (a, b) {
      const orderA = transportOrder[a.acheminementType] || 1000;
      const orderB = transportOrder[b.acheminementType] || 1001;
      return orderA - orderB;
    });
  }
}

export default new TransportBudgetMapper();
