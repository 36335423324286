export enum PriceCategory {
  Fixe = 'Contrat fixe marché',
  Arenh = 'Contrat ARENH',
  Trv = 'TRV',
  Indexe = 'INDEXE',
  Decote = 'Contrat Décote',
  IndexeTrv = 'Indexé TRV',
  Formule = 'Contrat à formule',
  BlocSpot = 'Bloc + Spot',
}

export enum AccountType {
  Customer = 'Customer',
  Partner = 'Partner',
  Other = 'Other',
  CentralPurchasingPartner = 'Partenaire Centrale Achat',
  Supplier = 'Fournisseur',
  Cci = 'CCI',
  CorporateClub = "Club d'Entreprise",
  Franchise = 'Franchise',
  Customs = 'Douanes',
}
