import {
  Commodity,
  ConcurrencyOptions,
  HttpClient,
} from '@Collectif-Energie/collective-lib';
import {
  SalesforceProfilEnergetique,
  SalesforceProfilEnergetiqueStatus,
} from '../../../server/modules/salesforce/models/salesforce.interface';
import config from '../../config/config';

export class SalesforceGatewayClient extends HttpClient {
  constructor() {
    const headers = { 'x-api-key': config.salesforceGateway.apiKey };
    const options: ConcurrencyOptions = {
      maxConcurrency: 30,
      runsAllowedInDuration: 30,
      duration: 1,
    };

    super(options, `${config.salesforceGateway.baseUrl}/api/v1`, headers);
  }

  public fetchProfilEnergetiques = async (
    prms: string[]
  ): Promise<SalesforceProfilEnergetique[]> => {
    const { data } = await this.get('/profil-energetiques/', {
      params: {
        prms,
        mapResults: true,
        commodity: Commodity.Power,
        status: SalesforceProfilEnergetiqueStatus.Active,
      },
    });
    return data.result;
  };
}

export default new SalesforceGatewayClient();
