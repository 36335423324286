export default Object.freeze({
  BASE: 1,
  HP: 2,
  HC: 3,
  Pointe: 4,
  PTE: 5,
  HPH: 6,
  HCH: 7,
  HPE: 8,
  HPB: 9,
  HCE: 10,
  HCB: 11,
});

export const orderedTemporalClass = [
  'BASE',
  'HP',
  'HC',
  'PTE',
  'HPH',
  'HCH',
  'HPE',
  'HPB',
  'HCE',
  'HCB',
];

export const classicTemporalClass = [
  'BASE',
  'HP',
  'HC',
  'PTE',
  'HPH',
  'HCH',
  'HPE',
  'HCE',
];

export const priceCategory = [
  { label: 'Contrat fixe marché', code: 'Contrat fixe marché' },
  { label: 'TRV', code: 'TRV' },
  { label: 'Indéxé marché', code: 'INDEXE' },
  { label: 'Contrat ARENH', code: 'Contrat ARENH' },
  { label: 'Indexé TRV', code: 'Indexé TRV' },
];
